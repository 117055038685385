export default {
  pageTitle: "Gonçalo Roquette - Terapias",
  header: {
    home: "Home",
    changeLangButton: "🇬🇧",
    cta: "Marcações",
    therapies: "Terapias",
    therapist: "Terapeuta",
    testimonials: "Testemunhos",
    videos: "Vídeos",
    prices: "Preços",
    contacts: "Contactos",
    logoAlt: "Gonçalo Roquette",
  },
  hero: {
    title: "Gonçalo Roquette",
    cta: "Marque uma sessão",
    knowMore: "Saber mais",
  },
  about: {
    title: "Terapeuta",
    description: `Gonçalo,45 anos.

Nasci em Lisboa e cresci entre a cidade e o campo, a serra de Sintra mais concretamente, e é daqui que surge a minha forte ligação à natureza: sempre retemperadora para mim.

O caos da cidade constratava com a calma do campo, que me fazia sentir tão bem, mesmo sem ter a noção do processo que desencadeava em mim, simplesmente sentia, no corpo e na mente.

Desde cedo fui curioso, o que me levou a procurar diversas formas de me expressar como ser humano.

Comecei por querer conhecer o Mundo. Viajei pela Europa, América do Sul, Médio Oriente,África e Ásia, um total de 46 paises. Mas foi aos 18 anos, na viagem de 3 meses que fiz pela Índia e Nepal que a minha espiritualidade despertou. No fundo, esteve sempre presente na minha vida, só não tinha essa consciência, hoje tenho.

Estudei Marketing e Publicidade, ideal para uma cabeça que sempre fervilhou de ideias, não me chegou para acalmar o turbilhão...

Decidi estudar Fotografia e Vídeo. Aqui encontrei durante 17 anos a forma certa de me expressar. Fui Freelancer e tive a minha própria Produtora de Filmes durante 7 anos. Fiz um pouco de tudo, programas de televisão, Documentários, Curtas Metragens, Institucionais, Videoclips...

Até que chegou O dia!

Sentia-me exausto, precisava desacelarar, os meus dias eram passados em permanente stress( nesta àrea profissional é tudo para ontem) sem espaço para viver o presente. Senti que tinha de mudar de vida.

Foi então que em 2019 iniciei formações como Mestre de Reiki, Cristaloterapia e Terapia de Som. Era claramente esta a mudança que precisava. A minha vida mudou radicalmente, e hoje sou Terapeuta.

É para isso que aqui estou. Para partilhar o que aprendi, ajudar a tornar os outros pessoas mais equilibradas, na sua forma física, emocional e espiritual, numa reconexão com elas próprias, com o seu Ser Original, Seres de Amor, Paz e Harmonia.`,
    meTitle: "Sobre mim",
    certificatesTitle: "Formações e Certificações",
    certificates: `* Terapia e Massagem de Som
Escola Internacional de Taças Tibetanas
Formador: Mestre de Taças e Gongos Miguel Lourenço

* Sound Therapy
Centre of Excellence

* Reiki Shoden Nível 1
Trabalho de Luz
Formador: Mestre de Reiki Armando Leal

* Reiki Okuden Nível 2
Trabalho de Luz
Formador: Mestre de Reiki Armando Leal

* Reiki Shinpiden e Gokukaiden Nível 3 Mestre e Professor
Trabalho de Luz
Formador: Mestre de Reiki Armando Leal

* Cristaloterapia Suishou
CENIF Centro Português de Investigação e Formação em Terapias Complementares
Formador: Mestre de Reiki João Magalhães

* Mundo dos Cristais
Espaço EU
Formador: Terapeuta Carla Liliana Oliveira

* Espiritualidade Quântica
Academia Pedro Frias
Formador: Pedro Frias

* Canalização e Terapêutica com os Seres de Luz
Trabalho de Luz
Formador: Metre Reiki Armando Leal`,
    imageAlt: "Gonçalo Roquette",
  },
  cta: {
    title: `"If you want to find the secrets of the Universe, think in terms of energy, frequency and vibration" - Nikola Tesla`,
    cta: "Marque uma sessão",
  },
  cta2: {
    title: `"Future Medicine will be the medicine of frequencies" - Albert Einstein`,
    cta: "Marque uma sessão",
  },
  pricing: {
    title: "Preços",
    description: "",
    cards: [
      {
        title: "Terapia e Massagem de Som",
        popular: "Popular",
        price: 60,
        cta: "Marcar",
        items: ["Duração: 60 minutos"],
      },
      {
        title: "Banho de Som Individual",
        price: 60,
        cta: "Marcar",
        items: ["Duração: 60 minutos"],
      },
      {
        title: "Banho de Som Grupo",
        price: 15,
        cta: "Marcar",
        items: ["Duração: 60 minutos"],
      },
      {
        title: "Banho de Som Online",
        price: 8,
        cta: "Marcar",
        items: ["Duração: 60 minutos"],
      },
      {
        title: "Reiki Usui",
        price: 45,
        cta: "Marcar",
        items: ["Duração: 60 minutos"],
      },
      {
        title: "Cristaloterapia",
        price: 45,
        cta: "Marcar",
        items: ["Duração: 60 minutos"],
      },
      {
        title: "Reiki com Cristais",
        price: 45,
        cta: "Marcar",
        items: ["Duração: 60 minutos"],
      },
    ],
  },
  testimonials: {
    title: "Testemunhos",
    items: [
      // {
      //   text: "Uma experiência incrível! Faço Reiki com regularidade, Yoga, Meditação mas nunca tinha experimentado Um Banho de Som, na verdade, até há bem pouco tempo nem sabia que existia. É incrível o que o som e as vibrações das Taças Tibetanas provocaram em mim:um relaxamento profundo do corpo e da mente, uma calma e uma paz que duraram vários dias após a sessão. A voz e a serenidade do Gonçalo, a confiança que transmite no que está a fazer e a forma carinhosa com que nos recebe para esta experiência são qualquer coisa... Os Banhos de Som serão sem dúvida uma prática a incluir na minha rotina com vista tambèm a uma ligação cada vez maior ao meu Eu interior. Muito grata.",
      //   author: "Rita Freitas",
      // },
      {
        text: "Uma experiência que vai muito além da expectativa. Durante a sessão permite libertar a mente e sentir o corpo e o presente. Mas a melhor sensação vem com os momentos e horas a seguir. Um relaxamento total acompanhado de bem estar.",
        author: "Diogo Teixeira",
      },
      {
        text: "Maravilhosa experiência com o Banho de Som. As Taças Tibetanas emanam uma energia intensa que se sente no corpo. Simplesmente deixei-me ir! Obrigado!",
        author: "Vera Costa",
      },
      {
        text: "Recomendo! Sessão relaxante, envolvente e fluente na dinâmica de boas vibrações emanadas pelas Taças. A repetir.",
        author: "António Santos",
      },
      // {
      //   text: "Uma experiência muito interessante, grande relaxamento do corpo e da mente proporcionando os sons e vibrações sonoras interação com o corpo e permitir sensações agradavelmente especiais. Os sons dão também uma sensação de viagem da mente! Obrigado pela contribuição de bem estar.",
      //   author: "Pedro Sebes",
      // },
      // {
      //   text: "Adorei a sessão, uma experiência sonora vibrante e meditativa. Recomendo!",
      //   author: "João Gomes",
      // },
      {
        text: "Uma experiência de relaxamento fantástica, em que nos perdemos no som e nos sentimos a pairar, sentindo o corpo a expulsar as tensões.",
        author: "Joana Graça",
      },
    ],
  },
  videos: {
    title: "Vídeos",
    items: [
      {
        title: "Banho de som 8 minutos para transmutar energias",
        description: "Usar headphones para uma melhor experiência.",
        url: "https://www.youtube.com/watch?v=IOmdSS0JzJc",
      },
      {
        title: "Taça Terapêutica Nº6",
        description: `Diâmetro: 21cm
Nota: F
É a Taça ideal para a garganta, laringe, faringe, traqueia, cordas vocais.`,
        url: "https://www.youtube.com/watch?v=sXHK_lOU-44",
      },
      {
        title: "Taça Terapêutica Nº4",
        description: `Diâmetro:13cm
Nota: F#
Taça ideal para aliviar sinosite e alergias.`,
        url: "https://www.youtube.com/watch?v=-zlAB7JlxH4",
      },
      {
        title: "Taça Terapêutica Nº7",
        description: `Diâmetro: 18cm
Nota: D
Indicada para problemas de estômago e como estimulante para os intestinos.
Ideal para quem sofre de obstipação.
Dores de estômago, dificuldades de digestão, úlceras. Não resolve o refluxo.
É uma Taça que consegue captar e emanar grandes quantidades de energia.`,
        url: "https://www.youtube.com/watch?v=3mmpF74sMzA",
      },
      {
        title: "Taça Terapêutica Nº2",
        description: `Diâmetro: 24cm
Nota: D#
Taça ideal para pés, mãos, músculos, tendões e articulações.`,
        url: "https://www.youtube.com/watch?v=aQh-SPcCos4",
      },
      {
        title: "Banho de Som 8 minutos para subir a energia",
        description: "Usar headphones para uma melhor experiência",
        url: "https://www.youtube.com/watch?v=scylS7v2NaY",
      },
      {
        title: "REIKI SOUND HEALING",
        description: "",
        url: "https://www.youtube.com/watch?v=VT8IHRzFrds",
      },
    ],
  },
  therapies: {
    title: "Terapias",
    description:
      "A Terapia de Som sincroniza as ondas cerebrais para atingir estados profundos de relaxamento, ajudando a restaurar as frequências vibratórias normais das células do corpo.",
    items: [
      {
        title: "Terapia e Massagem de Som com Taças Tibetanas e Gongos",
        description: `Terapia e Massagem de Som é a aplicação de taças tibetanas terapêuticas sobre zonas especificas do corpo com o intuito de restaurar a vibração original e saudável do ser humano.

Promove o alívio do stress a vários níveis, as emoções tornam-se mais tranquilas e a mente mais clara, provocando um efeito relaxante que perdura por vários dias após a sessão.

As Taças tibetanas são consideradas um dos instrumentos de Terapia de Som mais forte para a cura, uma técnica de regeneração ancestral.

Benefícios para o Corpo e Mente:

* O som é sem dúvida um dos meios de cura mais poderoso na medida em que conduz o Ser Humano a diferentes estados de consciência.
* As Taças Tibetanas e os Gongos harmonizam-se (som e vibração) e permitem um relaxamento profundo do cérebro. Quando relaxamos a concentração melhora e as tensões e bloqueios emocionais são aliviados. Questões como baixa autoestima, medo, raiva, ansiedade, depressão ou insónia podem muitas vezes ser atenuados através da aplicação desta terapia, recomendada também a crianças com distúrbios hiperativos.
* No plano físico, dá-se a eliminação de toxinas do corpo e a memória saudável das células é estimulada fazendo com que as células não saudáveis se autodestruam.
* Os sons únicos das Taças Tibetanas estabilizam a pressão sanguínia e promovem uma boa circulação. Estimulam o sistema digestivo, bem como o imunológico combatendo problemas respiratórios (ex: asma e bronquite). Favorecem a regeneração óssea e muscular, assim como o alívio de dores articulares, ciáticas ou enxaquecas.
* Os sons e vibrações das Taças Tibetanas e Gongos afetam os 4 níveis humanos, o físico, o mental, o emocional e o espiritual.
* Depois de uma sessão, o corpo e a mente voltam a estar sintonizados com o nosso interior. As Taças Tibetanas emitem o som e a frequência que faz reviver os códigos básicos de DNA e ativa os códigos ainda adormecidos ou suprimidos.

O efeito físico do som e vibração no corpo:

* Relaxamento profundo e regeneração muscular.
* Alívio de dores nas articulações, músculos, ciática, dor de cabeça e enxaqueca, sistema digestivo, lesões na coluna, ombros e melhora da circulação sanguínea.
* Melhora o processo de digestão.
* Átomos, moléculas, células, tecidos e orgãos funcionam de forma mais sincronizada.
* Melhora o fluxo de energia.
* Eliminação de toxinas do corpo.
* Melhora o sistema imunológico.

Efeito Mental e Emocional do som e vibração:

* Mente relaxada, tensões e bloqueios mentais/emocionais são aliviados.
* Melhor concentração.
* Sentimentos internos são ativados e libertados.
* Mais energia e força na vida quatidiana.
* Dor mental ou emocional aliviada.
* Melhora da autoconfiança e aumento do potencial criativo e produtivo.

Efeito Espiritual do som e vibração:

* Sentimento positivo de si mesmo.
* Melhor equilíbrio e harmonia no dia a dia.
* Experiências de êxtase.
* Limpeza do Chakras (centros energéticos) e dos Nadis (canais astrais)
* Abre caminho para uma vibração de amor mais intensa.`,
      },
      {
        title: "Meditação e Banho de Som com Taças Tibetanas e Gongos",
        description: `Um Banho de Som é uma prática meditativa, uma forma de controlar a ansiedade, acalmar o sistema nervoso e bloquear todas as idéias e pensamentos da sua consciência enquanto se conecta com o corpo. Deitado sobre um tapete de Yoga deixe-se levar pelos sons e vibrações das Taças Tibetanas e dos Gongos, bem como pela sonoridade curativa de outros instrumentos usados na Terapia de Som.

Benefícios:

* Acalma o Corpo e a Mente
* Reduz o stress e a ansiedade
* Aumenta o foco e a clareza
* Díminui a tensão e a fadiga
* Melhora o humor e reduz os sentimentos de raiva
* Melhora o sono
* Eleva os sentimentos de bem-estar Espiritual

Depois de uma sessão de Banho de Som vai sentir uma ampla gama de emoções, desde felicidade, êxtase, relaxamento profundo, sensação de leveza, entre outros sentimentos de bem-estar.`,
      },
      {
        title: "Cristaloterapia",
        description: `A Cristaloterapia consiste na suave aplicação de Cristais sobre o corpo com vista ao tratamento de patologias e alívio de sintomas físicos, mentais e espirituais.

Os Cristais atuam como condutores de energia, criando elos entre a energia dispersas pelo corpo, restabelecendo o seu natural equilíbrio.

A composição química de um Cristal é muito semelhante à do Ser Humano sendo por isso utilizado há milhares de anos em processos de cura interna.

E tal como o Homem, os Cristais, possuem uma frequência vibratória energética própria, que varia segundo as suas características.

A atuação de um Cristal sobre o corpo humano é explicada pela lei da ressonância, um fenómeno físico que regista a transferência de energia de um corpo oscilante para outro criando um campo energético muito mais amplo. Quando o corpo humano entra em contacto com os Cristais, os campos vibratórios sintonizam-se numa frequência específica que transmite informações ao sistema nervoso. A partir daí essas mensagens são enviadas para as células, gerando alterações metabólicas de cura.

Todos os Cristais independentemente da sua forma ou composição têm a capacidade de absorver, canalizar, conservar, concentrar e emitir energia, amplificando-a.

Um Cristal é uma formação sólida geometricamente regular, que ocorreu aquando da formação do Planeta Terra, tendo tal como ela passando por vários processos metamórficos. Os Cristais são por isso frequentemente considerados "registos" do planeta, uma espécie de ADN da Terra, uma vez que "presenciaram" toda a evolução do Planeta.

Benefícios da Cristaloterapia:

* Proporciona relaxamento profundo.
* Auxilia na eliminação de bloqueios.
* Maior sensação de bem estar.
* Ajuda a desintoxicar.
* Combate a Depressão e o Stress.
* Ajuda a combater alergias.
* Alivia dores de cabeça e dores musculares.
* Auxilia na elevação vibracional.
* Ativação energética e reequilíbrio dos Chakras
* Alivia sentimentos negativos
* Potencializa boas sensações`,
      },
      {
        title: "Reiki Usui",
        description: `Reiki é uma técnica criada no Japão pelo Mestre Mikao Usui. Esta Terapia consiste na imposição das mãos para a transferência de energia Vital do Universo através do terapeuta para o paciente. Desta forma é possível alinhar os centros de energia do corpo, conhecidos com Chakras, promovendo o equílibrio energético, necessário para manter o bem estar físico e mental.
Durante a sessão o terapeuta coloca as mãos (com ou sem toque físico no paciente) sobre o corpo do paciente com o objetivo de mudar a oscilação ou vibração das energias trazendo benefícios comprovados como o alívio de dores e redução de sintomas de ansiedade e stress.

Principais benefícios:

* Diminui sintomas de ansiedade e stress:
Esta Terapia é um excelente aliado para o tratamento da ansiedade devido à sensação de relaxamento que proporciona. Promove o sentimento de paz interior.

* Ajuda no tratamento da Depressão:
Através da aplicação das técnicas de imposição das mãos é possivel alinhar a Energia Vital do corpo e reduzir os sintomas provocados pela depressão, como tristeza excessiva, cansaço físico e perda de energia e de interesse nas atividades que antes geravam prazer.

* Redução de dores crónicas:
O relaxamento produzido durante a prática de Reiki contribui para a diminuição das dores crónicas como dor na coluna e de cabeça , pois é capaz de reduzir as tensões e fadiga muscular. Os efeitos sobre as dores crónicas podem ser sentidos nas primeiras sessões , pois o terapeuta vai desacelarar a energia do paciente , mudando a vibração e velocidade, produzindo ondas alinhadas nos Chakras equivalentes aos orgãos afetados.

* Alivia os sintomas da Insónia:
A sensação de bem-estar provocada pela terapia Reiki é capaz de aumentar a libertação de hormonas como a serotonina e endorfina, que são excelentes aliados no combater a insónia.

* Melhora a qualidade de vida:
Através do Reiki melhora-se a qualidade de vida, pois é uma terapia que liberta as tensões emocionais, fazendo uma limpeza na mente o que promove um relaxamento interior. O Reiki gera no paciente sentimentos de esperança , bem-estar, alegria, paz e autoconfiança.`,
      },
    ],
  },
  contacts: {
    contacts: "Contactos / Marcações",
    phoneLabel: "Telefone",
    phone: "+351 933 956 660",
    emailLabel: "E-Mail",
    email: "goncaloroquetteterapias@gmail.com",
    whatsapp: "351933956660",
    whatsappText: "Quero saber mais sobre Terapias de som",
  },
  footer: {
    logoAlt: "Gonçalo Roquette",
    site: "goncaloroquette.com",
    instagram: "https://www.instagram.com/goncaloroquette/",
  },
  seo: {
    title: "Gonçalo Roquette - Terapias",
    description:
      "Terapias / Terapia de Som / Cristaloterapia / Reiki Tradicional / Taças Tibetanas",
    url: "https://goncaloroquette.com/",
    image: "https://goncaloroquette.com/logo.png",
  },
};
